
import { Component, Mixins } from 'vue-property-decorator';
import CookieLaw from 'vue-cookie-law';
import NavBar from '@/components/TheNavBar.vue';
import PageHeader from '@/components/PageHeader.vue';
import Spinner from '@/components/TheSpinner.vue';
import Snackbars from '@/components/Snackbars.vue';
import TheFooter from '@/components-smart/TheFooter.vue';
import { namespace } from 'vuex-class';
import { UseCurrentLocaleMixin } from './mixins/use-current-locale.mixin';
import { StaticContentType } from './enums/static-content-type.enum';
import { StaticContent } from './models/static-contents/static-content.interface';
import { Homepage } from './models/static-contents/homepage.interface';
import { translateApi } from './utils/translate-api';

const appModule = namespace('app');
const staticContentsModule = namespace('staticContents');

@Component<App>({
  components: {
    NavBar,
    Spinner,
    Snackbars,
    PageHeader,
    TheFooter,
    CookieLaw,
  },
  metaInfo() {
    return {
      titleTemplate: titleChunk => {
        return titleChunk ? `${titleChunk} - Mezure` : `Mezure`;
      },
      htmlAttrs: {
        lang: this.currentLocale.code,
      },
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.seoDescription,
        },
      ],
    };
  },
  filters: {
    translateApi,
  },
})
export default class App extends Mixins(UseCurrentLocaleMixin) {
  @appModule.Getter('getLoading')
  loading!: boolean;

  @appModule.Action('setWindowWidth')
  setViewWidth!: (width: number) => number;

  @staticContentsModule.Action('fetchStaticContent')
  fetchStaticContent!: (type: StaticContentType) => Promise<StaticContent>;

  @staticContentsModule.Getter('getStaticContentByType')
  getStaticContentByType!: (type: StaticContentType) => Homepage;

  windowHeight = 0;
  homepage: Homepage | null = null;

  userAcceptedCookies: boolean | null = null;

  $refs!: Vue['$refs'] & {
    cookiePopup: InstanceType<typeof CookieLaw>;
  };

  get isViewHeightBigEnoughForFixedNav() {
    // When viewHeight is smaller than 515px (phone in landscape mode),
    // a fixed navbar would not show buttons outside of the screen
    // making it impossible for the user to navigate the other options
    return this.windowHeight > 515;
  }

  get seoDescription() {
    if (this.homepage) {
      return translateApi((this.homepage as Homepage).seoDescription.value, this.$i18n.locale);
    }
    return '';
  }

  onResize() {
    this.windowHeight = window.innerHeight;

    // Mobile viewport bug fix (vh takes browser bottom bar into account)
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.setViewWidth(window.innerWidth);
  }

  async created() {
    if (!this.getStaticContentByType(StaticContentType.Homepage)) {
      await this.fetchStaticContent(StaticContentType.Homepage);
    }
    this.homepage = this.getStaticContentByType(StaticContentType.Homepage);
  }

  async enableGoogleAnalytics() {
    this.userAcceptedCookies = this.$refs.cookiePopup.isAccepted();

    if (this.userAcceptedCookies) {
      this.$gtag.optIn();
      this.$gtm.enable(true);
    }
  }

  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
}
