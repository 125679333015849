import { extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import fr from 'vee-validate/dist/locale/fr.json';
import nl from 'vee-validate/dist/locale/nl.json';
import { confirmed, email, length, required, regex } from 'vee-validate/dist/rules';

/* eslint-disable no-useless-escape  */
/* eslint-disable @typescript-eslint/camelcase  */

extend('required', { ...required });
extend('email', email);
extend('confirmed', confirmed);
extend('length', length);
extend('verify_password', {
  validate: (value: string) => {
    const strongRegex = new RegExp(/^(?=.{8,})/);

    return strongRegex.test(value);
  },
});
extend('phone', regex);
extend('valid_youtube_url', {
  validate: (value: string) => {
    const strongRegex = new RegExp(/^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm);

    return strongRegex.test(value);
  },
});

localize({
  en: {
    messages: {
      ...en.messages,
      verify_password: 'The password must contain at least 8 characters',
      phone: 'No valid phone or mobile number',
    },
  },
  nl: {
    messages: {
      ...nl.messages,
      verify_password: 'Het wachtwoord moet bestaan uit minimaal 8 karakters',
      phone: 'Geen geldig telefoon of mobiel nummer',
    },
  },
  fr: {
    messages: {
      ...fr.messages,
      verify_password: 'Le mot de passe doit contenir au moins 8 caractères',
      phone: 'Pas un numéro de téléphone ou mobile valide',
    },
  },
});

const currentLocale = process.env.VUE_APP_I18N_LOCALE || 'nl';
localize(currentLocale);
