
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class HomepageOfferItem extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  title!: string;

  @Prop({
    type: String,
    required: true,
  })
  text!: string;

  @Prop({
    type: Boolean,
  })
  borderBottom!: boolean;

  @Prop({
    type: Boolean,
  })
  borderLeft!: boolean;

  @Emit('see-more')
  onSeeMoreClicked() {
    return true;
  }
}
