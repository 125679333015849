import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import app from './modules/app.module';
import auth from './modules/auth.module';
import categories from './modules/categories.module';
import courses from './modules/courses.module';
import roomTypes from './modules/roomTypes.module';
import bookings from './modules/bookings.module';
import faqs from './modules/faqs.module';
import languages from './modules/languages.module';
import locations from './modules/locations.module';
import pages from './modules/pages.module';
import registration from './modules/registration.module';
import staffMembers from './modules/staff-member.module';
import staticContents from './modules/static-contents.module';
import users from './modules/users.module';
import customerReviews from './modules/customer-reviews.module';
import customers from './modules/customers.module';
import newsletterSubscriptions from './modules/newsletter-subscriptions.module';
import invoices from './modules/invoices.module';

Vue.use(Vuex);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

const store: StoreOptions<RootState> = {
  modules: {
    auth,
    app,
    users,
    staticContents,
    courses,
    roomTypes,
    bookings,
    locations,
    languages,
    categories,
    staffMembers,
    registration,
    faqs,
    pages,
    customerReviews,
    customers,
    newsletterSubscriptions,
    invoices,
  },
};

export default new Vuex.Store(store);
