import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import Location from '@/models/locations/location.class';
import LocationsService from '@/api/services/locations/locations.service';

const locationsService = new LocationsService();

export interface LocationState {
  all: Location[];
  apiParams: FetchAllParams;
}

const initialState: LocationState = {
  all: [],
  apiParams: {},
};

const getters: GetterTree<LocationState, RootState> = {
  ALL: state => state.all,
  APIPARAMS: state => state.apiParams,
};

const mutations: MutationTree<LocationState> = {
  SET_ALL(state, payload: Location[]) {
    state.all = payload;
  },
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
};

const actions: ActionTree<LocationState, RootState> = {
  async FETCH_UNPAGINATED(context, filter?: FetchAllParams) {
    try {
      context.commit('SET_API_PARAMS', filter);
      const locations = await locationsService.fetchAll(filter);
      context.commit('SET_ALL', locations);
      return locations;
    } catch (error) {
      return error.response;
    }
  },
};

const locationsModule: Module<LocationState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
export default locationsModule;
