import StaffMembersService from '@/api/services/staff-members/staff-members.service';
import StaffMember from '@/models/staff-members/staff-member.class';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';

const staffMembersService = new StaffMembersService();

export interface StaffMemberState {
  all: StaffMember[];
  apiParams: FetchAllParams;
}

const initialState: StaffMemberState = {
  all: [],
  apiParams: {},
};

const getters: GetterTree<StaffMemberState, RootState> = {
  all: state => state.all,
  apiParams: state => state.apiParams,
};

const mutations: MutationTree<StaffMemberState> = {
  setAll(state, payload: StaffMember[]) {
    state.all = payload;
  },
};

const actions: ActionTree<StaffMemberState, RootState> = {
  async fetchUnpaginated(context, payload) {
    try {
      const params = { ...payload };
      const staffMembers = await staffMembersService.fetchStaffMembers(params);
      context.commit('setAll', staffMembers);
      return staffMembers;
    } catch (error) {
      return error.response;
    }
  },
};

const staffMembersModule: Module<StaffMemberState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default staffMembersModule;
