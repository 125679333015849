import { Invoice } from '@/models/invoices/invoice.class';
import EntitiesService from '../entities/entities.service';
import api from '@/api/api';

export default class InvoicesService extends EntitiesService<Invoice> {
  get endpoint() {
    return '/invoices';
  }
  async getPdf(invoiceId: string): Promise<Blob> {
    const response = await api.get<Blob>(`${this.endpoint}/${invoiceId}/pdf`, {
      responseType: 'blob',
    });

    return response.data;
  }
}
