import CategoriesService from '@/api/services/categories/categories.service';
import Category from '@/models/categories/category.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const categoriesService = new CategoriesService();

export interface CategoryState {
  all: Category[];
  apiParams: FetchAllParams;
}

const initialState: CategoryState = {
  all: [],
  apiParams: {},
};

const getters: GetterTree<CategoryState, RootState> = {
  ALL: state => state.all,
  APIPARAMS: state => state.apiParams,
};

const mutations: MutationTree<CategoryState> = {
  SET_ALL(state, payload: Category[]) {
    state.all = payload;
  },
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
};

const actions: ActionTree<CategoryState, RootState> = {
  async FETCH_UNPAGINATED(context, filter?: FetchAllParams) {
    try {
      context.commit('SET_API_PARAMS', filter);
      const categories = await categoriesService.fetchAll(filter);
      context.commit('SET_ALL', categories);
      return categories;
    } catch (error) {
      return error.response;
    }
  },
};

const categoriesModule: Module<CategoryState, RootState> = {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: initialState,
};
export default categoriesModule;
