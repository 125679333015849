import api from '@/api/api';
import { CreateRegistrationDto } from '@/models/registrations/create-registration-dto.class';
import { Registration } from '@/models/registrations/registration.class';
import EntitiesService from '../entities/entities.service';

export default class RegistrationsService extends EntitiesService<Registration> {
  get endpoint() {
    return '/registrations';
  }

  async createRegistration(entity: CreateRegistrationDto): Promise<Registration> {
    const response = await api.post<Registration>(this.endpoint, entity);
    return response.data;
  }

  async createPayment(registrationId: string): Promise<Registration> {
    const response = await api.post<Registration>(`${this.endpoint}/create-payment`, {
      id: registrationId,
    });
    return response.data;
  }
}
