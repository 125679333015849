
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User } from '../models/users/user.class';
import { Locale } from '../models/shared/locale.interface';
import { SUPPORTED_LOCALES } from '../utils/constants';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';

const authModule = namespace('auth');

@Component({
  components: {},
})
export default class TheNavBar extends Mixins(UseCurrentLocaleMixin) {
  @Prop({
    type: Boolean,
    default: true,
  })
  isFixedNav!: boolean;

  @authModule.Getter('isLoggedIn')
  isLoggedIn!: boolean;

  @authModule.Getter('token')
  token!: string;

  @authModule.Getter('authenticatedUser')
  user!: User;

  @authModule.Action('logOut')
  logOut!: () => Promise<void>;

  darkNavbar = true;
  lastScrollPosition = 0;
  navbarIsVisible = false;

  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.navbarIsVisible = false;
  }

  get logoPath() {
    return require(`../assets/img/logo${this.darkNavbar ? '-white' : ''}.svg`);
  }

  get locales() {
    return SUPPORTED_LOCALES;
  }

  changeLocale(locale: Locale) {
    const currentPath = this.localeBaseUrlPath
      ? this.$route.path.substring(this.localeBaseUrlPath.length)
      : this.$route.path;

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    this.$router.replace(`${locale.base}${currentPath}`).catch(err => {});
  }

  async logOutAndRedirect() {
    await this.logOut();
    this.$router.push(`${this.localeBaseUrlPath}/`);
  }

  onScroll() {
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
    if (currentScrollPosition < 0) {
      return;
    }

    // Only changes the navbar when the user scrolls more than 60px
    if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
      return;
    }

    this.darkNavbar = currentScrollPosition < this.lastScrollPosition;
    this.lastScrollPosition = currentScrollPosition;
  }

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }
}
