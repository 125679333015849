import LanguagesService from '@/api/services/languages/languages.service';
import Language from '@/models/languages/language.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const languagesService = new LanguagesService();

export interface LanguageState {
  all: Language[];
  apiParams: FetchAllParams;
}

const initialState: LanguageState = {
  all: [],
  apiParams: {},
};

const getters: GetterTree<LanguageState, RootState> = {
  ALL: state => state.all,
  APIPARAMS: state => state.apiParams,
};

const mutations: MutationTree<LanguageState> = {
  SET_ALL(state, payload: Language[]) {
    state.all = payload;
  },
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
};

const actions: ActionTree<LanguageState, RootState> = {
  async FETCH_UNPAGINATED(context, filter?: FetchAllParams) {
    try {
      context.commit('SET_API_PARAMS', filter);
      const languages = await languagesService.fetchAll(filter);
      context.commit('SET_ALL', languages);
      return languages;
    } catch (error) {
      return error.response;
    }
  },
};

const languagesModule: Module<LanguageState, RootState> = {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: initialState,
};
export default languagesModule;
