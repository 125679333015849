import Vue from 'vue';
import flatpickrTypes from 'flatpickr';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export function getFlatPickrConfig(): flatpickrTypes.Options.Options {
  return {
    enableTime: false,
    // eslint-disable-next-line @typescript-eslint/camelcase
    time_24hr: true,
    altInput: true,
    altFormat: 'd-m-Y',
    dateFormat: 'Z',
    defaultHour: 12,
    wrap: true,
    plugins: [],
  };
}

Vue.use(flatPickr);
