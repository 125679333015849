import '@/plugins/bootstrap-vue';
import '@/plugins/flatpickr';
import '@/plugins/font-awesome';
import '@/plugins/g-tag';
import i18n from '@/plugins/i18n';
import '@/plugins/infinite-loading';
import '@/plugins/vee-validate';
import '@/plugins/vue-inline-svg';
import '@/plugins/vue-lazyload';
import '@/plugins/vue-meta';
import '@/plugins/vue-glide-js';
import '@/plugins/vue-hotjar';
import '@/plugins/vue-vimeo-player';
import '@/plugins/vue-social-sharing';
import '@/plugins/vue2-gtm';
import router from '@/router';
import store from '@/store/store';
import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
