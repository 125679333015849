import { ApiErrorMessages } from '@/enums/api-error-messages.enum';
import { AxiosError } from 'axios';
import { apiErrorMessageToTranslationKey } from './helpers';

export function generateReadableAxiosError(error: AxiosError): string {
  const response = error as AxiosError;

  const apiErrorMessage = response.message
    ? (response.message as ApiErrorMessages)
    : ('' as ApiErrorMessages);

  return apiErrorMessageToTranslationKey(apiErrorMessage);
}
