import { render, staticRenderFns } from "./Snackbars.vue?vue&type=template&id=06b7f53c&scoped=true&"
import script from "./Snackbars.vue?vue&type=script&lang=ts&"
export * from "./Snackbars.vue?vue&type=script&lang=ts&"
import style0 from "./Snackbars.vue?vue&type=style&index=0&id=06b7f53c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b7f53c",
  null
  
)

export default component.exports