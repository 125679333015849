import { RawLocation } from 'vue-router';

export function createRouteWithLocaleParam(
  routeName: string,
  currentLocale: string,
  params?: { [key: string]: unknown },
): RawLocation {
  const route: RawLocation = {
    name: routeName,
    params: {
      locale: currentLocale,
      ...params,
    },
  };

  if (currentLocale === 'nl') {
    delete route.params?.locale;
  }

  return route;
}
