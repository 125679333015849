import MeetingRoomBooking from '@/models/facilities/meeting-room-booking.class';
import EntitiesService from '../entities/entities.service';
import api from '@/api/api';

export default class BookingOfferService extends EntitiesService<MeetingRoomBooking> {
  get endpoint() {
    return '/bookings/offer-example';
  }

  async createBookingOffer(entity: MeetingRoomBooking): Promise<MeetingRoomBooking> {
    const response = await api.post<MeetingRoomBooking>(`${this.endpoint}/${entity._id}`, entity, {
      responseType: 'blob',
    });

    return response.data;
  }
}
