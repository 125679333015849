
import { UseCurrentLocaleMixin } from '@/mixins/use-current-locale.mixin';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import env from '../../env.config';

@Component({ components: {} })
export default class VideoPlayer extends Mixins(UseCurrentLocaleMixin) {
  @Watch('$route', { deep: true })
  onRouteChanged() {
    this.getVideoId();
  }
  getVideoId(): string {
    let videoId = '';
    if (this.localeBaseUrlPath.includes('')) {
      videoId = env.VIDEO_ID_NL;
    }
    if (this.localeBaseUrlPath.includes('fr')) {
      videoId = env.VIDEO_ID_FR;
    }
    return videoId;
  }
}
