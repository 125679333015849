import RegistrationsService from '@/api/services/registrations/registrations.service';
import SessionsService from '@/api/services/sessions/sessions.service';
import StudentsService from '@/api/services/students/students.service';
import { ApiErrorMessages } from '@/enums/api-error-messages.enum';
import { FilterOperation } from '@/models/app/filter-operation.enum';
import { Customer } from '@/models/customers/customer.class';
import { CreateRegistrationDto } from '@/models/registrations/create-registration-dto.class';
import { Registration } from '@/models/registrations/registration.class';
import Session from '@/models/sessions/session.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { Student } from '@/models/students/student.class';
import { User } from '@/models/users/user.class';
import { apiErrorMessageToTranslationKey } from '@/utils/helpers';
import { AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const sessionsService = new SessionsService();
const studentsService = new StudentsService();
const registrationsService = new RegistrationsService();

export interface RegistrationState {
  selectedSession: Session | null;
  students: Student[];
  currentUserStudent: Student | null;
  registration: Registration | null;
  registrations: Registration[];
}

const initialState: RegistrationState = {
  selectedSession: null,
  students: [],
  currentUserStudent: null,
  registration: null,
  registrations: [],
};

const getters: GetterTree<RegistrationState, RootState> = {
  selectedSession: state => state.selectedSession,
  students: state => state.students,
  currentUserStudent: state => state.currentUserStudent,
  registration: state => state.registration,
  registrations: state => state.registrations,
};

const mutations: MutationTree<RegistrationState> = {
  setSession(state, payload: Session) {
    state.selectedSession = payload;
  },
  clearData(state) {
    state.selectedSession = null;
  },
  setStudents(state, payload: Student[]) {
    state.students = payload;
  },
  setCurrentUserStudent(state, payload: Student) {
    state.currentUserStudent = payload;
  },
  setRegistration(state, payload: Registration) {
    state.registration = payload;
  },
  setRegistrations(state, payload: Registration[]) {
    state.registrations = payload;
  },
};

function mapRegistrationToCreateRegistrationDto(registration: Registration): CreateRegistrationDto {
  const mappedRegistration: CreateRegistrationDto = {
    ...cloneDeep(registration),
    students: registration.students.map(student => student._id as string),
  };

  return mappedRegistration;
}

const actions: ActionTree<RegistrationState, RootState> = {
  async fetchSession(context, payload: string) {
    try {
      const session = await sessionsService.fetchOne(payload);
      context.commit('setSession', session);
      return session;
    } catch (error) {
      return error.response;
    }
  },
  clearData(context) {
    context.commit('clearData');
  },
  async fetchStudentFromCurrentUser(context) {
    try {
      const user: User = context.rootGetters['auth/authenticatedUser'];
      const filter: FetchAllParams = {
        filters: {
          email: {
            operation: FilterOperation.Equals,
            value: user.email,
          },
        },
      };

      const studentsResult = await studentsService.fetchStudents(filter);
      const student = studentsResult && studentsResult[0];
      if (studentsResult.length && student) {
        context.commit('setCurrentUserStudent', student);
        return student;
      }
      return false;
    } catch (error) {
      return error.response;
    }
  },

  async createStudentFromCurrentUser(context) {
    try {
      const user: User = context.rootGetters['auth/authenticatedUser'];
      const student: Student = new Student(
        user.firstName,
        user.lastName,
        user.email,
        (user.customer as Customer)._id,
      );

      const studentResult = await studentsService.create(student);
      if (studentResult) {
        context.commit('setCurrentUserStudent', studentResult);
        return studentResult;
      }
    } catch (error) {
      return error.response;
    }
  },
  async fetchStudents(context, filter?: FetchAllParams) {
    try {
      const students = await studentsService.fetchStudents(filter);
      context.commit('setStudents', students);
      return students;
    } catch (error) {
      return error.response;
    }
  },
  async createStudent(context, payload: Student) {
    try {
      const studentResult = await studentsService.create(payload);
      if (studentResult) {
        context.dispatch('fetchStudents');
        return studentResult;
      }
    } catch (error) {
      return error.response;
    }
  },
  async removeStudent(context, payload: Student) {
    try {
      const response = await studentsService.delete(payload._id as string);
      return response;
    } catch (error) {
      const response = error.response as AxiosResponse;
      if (response && response.status === 400) {
        const apiErrorMessage = response.data.message as ApiErrorMessages;
        return apiErrorMessageToTranslationKey(apiErrorMessage);
      } else {
        return apiErrorMessageToTranslationKey('' as ApiErrorMessages);
      }
    }
  },
  async updateStudent(context, payload: Student) {
    try {
      if (payload._id) {
        const studentResult = await studentsService.update(payload._id, payload);
        if (studentResult) {
          // context.dispatch('fetchStudents');
          return studentResult;
        }
      }
    } catch (error) {
      return error.response;
    }
  },
  async createRegistration(context, payload: Registration) {
    try {
      const mappedRegistration = mapRegistrationToCreateRegistrationDto(payload);
      const registrationResult = await registrationsService.createRegistration(mappedRegistration);
      if (registrationResult) {
        return registrationResult;
      }
    } catch (error) {
      return error.response;
    }
  },
  async fetchRegistration(context, registrationId: string) {
    try {
      const registration = await registrationsService.fetchOne(registrationId);
      context.commit('setRegistration', registration);
      return registration;
    } catch (error) {
      return error.response;
    }
  },
  async fetchRegistrations(context, filter?: FetchAllParams) {
    try {
      const registrations = await registrationsService.fetch(filter);
      context.commit('setRegistrations', registrations);
      return registrations;
    } catch (error) {
      return error.response;
    }
  },
  async createPayment(context, registrationId: string) {
    try {
      const registration = await registrationsService.createPayment(registrationId);
      context.commit('setRegistration', registration);
      return registration;
    } catch (error) {
      return error.response;
    }
  },
};

const registrationModule: Module<RegistrationState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default registrationModule;
