import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import env from '../../env.config';
import store from '@/store/store';

const http = axios.create({
  baseURL: env.OKF_API_ROOT,
});

http.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = store.getters['auth/token'];

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  },
);

http.interceptors.response.use(
  (success: AxiosResponse) => success,
  (error: AxiosError) => {
    // Add toast
    throw error;
  },
);

export default http;
