import api from '@/api/api';
import CustomerReview from '@/models/customer-reviews/customer-review.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { generateRequestConfig } from '@/utils/generate-request-config.util';
import { AxiosRequestConfig } from 'axios';
import EntitiesService from '../entities/entities.service';

export default class CustomerReviewsService extends EntitiesService<CustomerReview> {
  get endpoint() {
    return '/reviews';
  }

  async fetchCustomerReviews(params?: FetchAllParams): Promise<CustomerReview[]> {
    try {
      const config: AxiosRequestConfig = generateRequestConfig(params);
      const response = await api.get<CustomerReview[]>(`${this.endpoint}`, config);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
}
