export enum RouterView {
  Home = 'Home',
  PageNotFound = 'PageNotFound',
  LoginRegister = 'LoginRegister',
  MyAccount = 'MyAccount',
  Disclaimer = 'Disclaimer',
  Privacy = 'Privacy',
  Courses = 'Courses',
  AboutUs = 'AboutUs',
  CourseDetail = 'CourseDetail',
  CourseRegister = 'CourseRegister',
  Contact = 'Contact',
  Coaching = 'Coaching',
  PasswordResetRequest = 'PasswordResetRequest',
  PasswordReset = 'PasswordReset',
  BillingRedirect = 'BillingRedirect',
  BillingComplete = 'BillingComplete',
  StaticPage = 'StaticPage',
  PersonalData = 'PersonalData',
  LoginData = 'LoginData',
  InvoiceData = 'InvoiceData',
  RegistrationData = 'RegistrationData',
  BookingsData = 'BookingsData',
  Participants = 'Participants',
  Facilities = 'Facilities',
  FacilitiesBooking = 'FacilitiesBooking',
  NewsletterRegistration = 'NewsletterRegistration',
}
