/* eslint-disable no-useless-catch */
import api from '@/api/api';
import { StaticContentType } from '@/enums/static-content-type.enum';
import { StaticContent } from '@/models/static-contents/static-content.interface';

const endpoint = '/static-contents';

export default class StaticContentsService {
  async fetchOne(type: StaticContentType) {
    try {
      const response = await api.get<StaticContent>(`${endpoint}/${type}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
