
import { Component, Vue, Watch, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { v4 as uuid } from 'uuid';
import { gsap } from 'gsap';
import TheSearchbar from '../components/TheSearchbar.vue';
import { FetchAllParams } from '../models/shared/fetch-all-params.interface';
import Course from '../models/courses/course.class';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';
import { StaticContentType } from '../enums/static-content-type.enum';
import { Banner } from '../models/static-contents/banner.interface';
import { translateApi } from '../utils/translate-api';
import { StaticContent } from '../models/static-contents/static-content.interface';
import { UrlType } from '../enums/url-type.enum';
import HomepageOffer from '@/components/HomepageOffer.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { HomepageOffer as HomepageOfferModel } from '../models/static-contents/homepage-offer.interface';
import { Page } from '@/models/pages/page.class';

const appModule = namespace('app');
const coursesModule = namespace('courses');
const staticContentsModule = namespace('staticContents');
const pagesModule = namespace('pages');

type PageHeaderStaticContentType<T> = T;

@Component({
  components: {
    SearchBar: TheSearchbar,
    HomepageOffer,
    VideoPlayer,
  },
  filters: {
    translateApi,
  },
})
export default class PageHeader extends Mixins(UseCurrentLocaleMixin) {
  @appModule.Getter('headerHtml')
  headerHtml!: (payload: string) => void;

  @appModule.Getter('windowWidth')
  viewWidth!: number;

  @coursesModule.Action('setApiParams')
  setApiParams!: (payload: FetchAllParams) => void;

  @coursesModule.Getter('apiParams')
  apiParams!: FetchAllParams;

  @coursesModule.Getter('one')
  course!: Course;

  @pagesModule.Getter('one')
  page!: Page;

  @staticContentsModule.Action('fetchStaticContent')
  fetchStaticContent!: (type: StaticContentType) => Promise<StaticContent>;

  @staticContentsModule.Getter('getStaticContentByType')
  getStaticContentByType!: <T>(type: StaticContentType) => PageHeaderStaticContentType<T>;

  headerKey = '';
  banner: Banner | null = null;
  homepageOffer: HomepageOfferModel | null = null;
  dataLoaded = false;
  shouldAnimateOnLeave = false;

  $refs!: Vue['$refs'] & {
    headerContainer: HTMLDivElement;
    headerContent: HTMLDivElement;
    homeHeaderContent: HTMLDivElement;
  };

  @Watch('headerHtml')
  onHeaderHtmlChanged() {
    this.headerKey = uuid();
  }

  beforeEnter() {
    // This variable prevents the animation of starting on leave before it enters
    this.shouldAnimateOnLeave = true;
  }

  onEnter(el: HTMLElement, done: gsap.Callback) {
    const { headerContainer, headerContent, homeHeaderContent } = this.$refs;
    const timeline = gsap.timeline({ paused: true });
    timeline
      .fromTo(
        headerContainer,
        { height: 0 },
        { height: `${headerContent.clientHeight + 20}px`, duration: 0.4 },
      )
      .fromTo(headerContent, { x: 40, opacity: 0 }, { x: 0, opacity: 1, duration: 0.6 }, '-=0.3')
      .fromTo(
        homeHeaderContent,
        { x: 40, opacity: 0 },
        { x: 0, opacity: 1, duration: 0.6, onComplete: done },
        '-=0.2',
      );
    if (this.dataLoaded) {
      timeline.play();
    }
  }

  onLeave(el: HTMLElement, done: gsap.Callback) {
    const { headerContainer, headerContent, homeHeaderContent } = this.$refs;
    const timeline = gsap.timeline({ paused: true });
    timeline
      .fromTo(
        homeHeaderContent,
        {
          x: 0,
          opacity: this.shouldAnimateOnLeave && !this.isHome ? 1 : 0,
        },
        { x: 40, opacity: 0, duration: 0.3 },
      )
      .fromTo(
        headerContent,
        {
          x: 0,
          opacity: this.shouldAnimateOnLeave ? 1 : 0,
        },
        { x: 40, opacity: 0, duration: 0.3 },
      )
      .fromTo(
        headerContainer,
        { height: `${headerContent.clientHeight + 20}px` },
        { height: 0, duration: 0.4, onComplete: done },
        '-=0.2',
      );
    if (this.dataLoaded) {
      timeline.play();
    }
  }

  get searchInput() {
    return this.apiParams.search || '';
  }

  set searchInput(searchQuery: string) {
    this.setApiParams({ search: searchQuery });
  }

  get headerKeyValue() {
    return this.headerKey;
  }

  get isHome() {
    return this.$route.name === 'home';
  }

  get isCoursesList() {
    return this.$route.name === 'courses';
  }

  get isCourseDetail() {
    return this.$route.name === 'course';
  }

  get courseImageUrl() {
    return `url(${this.course?.banner?.url})`;
  }

  get homeBannerImageUrl() {
    return `url(${translateApi(this.banner?.image?.value, this.$i18n.locale)})`;
  }

  get homeHeaderHightlightTitle() {
    return translateApi(this.banner?.highlightTitle?.value, this.$i18n.locale);
  }

  get homeHeaderHightlightText() {
    return translateApi(this.banner?.highlightText?.value, this.$i18n.locale);
  }

  get homepageButtonLabel() {
    return translateApi(this.banner?.buttonLabel?.value, this.$i18n.locale);
  }

  get homepageButtonLinkIsExternalUrl() {
    return this.banner?.url.urlType === UrlType.Url;
  }

  get homepageButtonLink() {
    if (this.banner?.url.urlType === UrlType.Url) {
      return translateApi(this.banner.url.value, this.$i18n.locale);
    }
    if (this.banner?.url.urlType === UrlType.Course && this.course) {
      return `${this.localeBaseUrlPath}/courses/${translateApi(
        this.course.slug,
        this.$i18n.locale,
      )}`;
    }
    if (this.banner?.url.urlType === UrlType.Page && this.page) {
      return `${this.localeBaseUrlPath}/page/${translateApi(this.page.slug, this.$i18n.locale)}`;
    }
    return '';
  }

  onSearch(searchQuery: string) {
    this.searchInput = searchQuery;

    if (this.isHome) {
      if (searchQuery.length > 2) {
        this.$router.push({
          path: `${this.localeBaseUrlPath}/courses`,
          query: { searchQuery },
        });
      }
    } else {
      this.$router
        .replace({
          path: `${this.localeBaseUrlPath}/courses`,
          query: { searchQuery },
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
        .catch(err => {});
    }
  }

  async created() {
    if (!this.getStaticContentByType(StaticContentType.Banner)) {
      await this.fetchStaticContent(StaticContentType.Banner);
    }

    if (!this.getStaticContentByType(StaticContentType.HomepageOffer)) {
      await this.fetchStaticContent(StaticContentType.HomepageOffer);
    }

    this.banner = this.getStaticContentByType<Banner>(StaticContentType.Banner);
    this.homepageOffer = this.getStaticContentByType<HomepageOfferModel>(
      StaticContentType.HomepageOffer,
    );
    this.dataLoaded = true;
  }
}
