import MeetingRoomBooking from '@/models/facilities/meeting-room-booking.class';
import EntitiesService from '../entities/entities.service';
import api from '@/api/api';
import { Invoice } from '@/models/invoices/invoice.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { FilterOperation } from '@/models/app/filter-operation.enum';
import { Filters } from '@/models/app/filters.interface';
import { generateRequestConfig } from '@/utils/generate-request-config.util';
import { AxiosRequestConfig } from 'axios';

export default class BookingRoomService extends EntitiesService<MeetingRoomBooking> {
  get endpoint() {
    return '/bookings';
  }

  async createBooking(entity: MeetingRoomBooking): Promise<MeetingRoomBooking> {
    const response = await api.post<MeetingRoomBooking>(this.endpoint, entity);
    return response.data;
  }

  async fetchBookingsByUserId(userId: string): Promise<MeetingRoomBooking[]> {
    // always return bookings without status canceled
    const filters = {} as Filters;
    filters.filter = { value: 'canceled', operation: FilterOperation.NotEquals };
    const params = {} as FetchAllParams;
    params.filters = { ['status']: filters.filter };
    const config: AxiosRequestConfig = generateRequestConfig(params);
    const response = await api.get<MeetingRoomBooking[]>(
      `${this.endpoint}/user-bookings/${userId}`,
      config,
    );
    return response.data;
  }

  async approveBookingOffer(booking: MeetingRoomBooking, customerRef: string): Promise<Invoice> {
    const response = await api.post<Invoice>(`${this.endpoint}/approve-offer`, booking, {
      params: { customerRef },
    });
    return response.data;
  }
}
