
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Snackbar } from '@/models/app/snackbar.class';

const appModule = namespace('app');

@Component({})
export default class TheNavBar extends Vue {
  @appModule.Getter('snackbars')
  snackbars!: Snackbar[];

  @appModule.Mutation('removeSnackbar')
  closeSnackbar!: (index: number) => void;
}
