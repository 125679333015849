import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const appModule = namespace('app');

@Component
export class UseViewWidthHelpersMixin extends Vue {
  @appModule.Getter('windowWidth')
  viewWidth!: number;

  get isExtraSmallDevice() {
    return this.viewWidth < 576;
  }

  get isSmallDevice() {
    return this.viewWidth >= 576 && this.viewWidth < 768;
  }

  get isMediumDevice() {
    return this.viewWidth >= 768 && this.viewWidth < 992;
  }

  get isBiggerThanMediumDevice() {
    return this.viewWidth >= 768;
  }

  get isLargeDevice() {
    return this.viewWidth >= 992 && this.viewWidth < 1200;
  }

  get isExtraLargeDevice() {
    return this.viewWidth >= 1200;
  }
}
