import FaqsService from '@/api/services/faqs/faqs.service';
import { SortOrder } from '@/enums/sort-order.enum';
import { Filters } from '@/models/app/filters.interface';
import Faq from '@/models/faqs/faq.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { PaginatedResponse } from '@/models/shared/paginated-response.interface';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const faqsService = new FaqsService();

export interface FaqState {
  allUnpaginated: Faq[];
  all: PaginatedResponse<Faq>;
  apiParams: FetchAllParams;
}

const initialApiParams: FetchAllParams = {
  limit: 999,
  sort: [`sequence,${SortOrder.Asc}`],
};

const initialState: FaqState = {
  allUnpaginated: [],
  all: { docs: [], total: 0, limit: 0, page: 0, pages: 0 },
  apiParams: initialApiParams,
};

const getters: GetterTree<FaqState, RootState> = {
  ALL_PAGINATED: (state: FaqState) => state.all,
  API_PARAMS: (state: FaqState) => state.apiParams,
};

const mutations: MutationTree<FaqState> = {
  SET_ALL(state, payload: PaginatedResponse<Faq>) {
    state.all = payload;
  },
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
};

const actions: ActionTree<FaqState, RootState> = {
  async FETCH_ALL(context, filters?: Filters) {
    try {
      const params: FetchAllParams = { ...context.getters.API_PARAMS, filters };
      context.commit('SET_API_PARAMS', params);
      const faqs = await faqsService.fetch(params);
      context.commit('SET_ALL', faqs);
      return faqs;
    } catch (error) {
      error.response;
    }
  },
};

const faqsModule: Module<FaqState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default faqsModule;
