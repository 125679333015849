import { FilterParams } from '@/models/app/filter-params.interface';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { AxiosRequestConfig } from 'axios';
import { isBlank } from './is-blank.util';

export function generateRequestConfig(params?: FetchAllParams): AxiosRequestConfig {
  const config: AxiosRequestConfig = {};

  if (params) {
    const { filters, ...rest } = params;
    const filterParams: FilterParams = {};

    if (filters) {
      for (const [field, filter] of Object.entries(filters)) {
        if (!isBlank(filter.value)) {
          if (Array.isArray(filter.value)) {
            filterParams[field] = filter.value.map(
              (valueItem: unknown) => `${filter.operation}${valueItem}`,
            );
          } else {
            filterParams[field] = `${filter.operation}${filter.value}`;
          }
        }
      }
    }

    config.params = {
      ...rest,
      ...filterParams,
    };
  }

  return config;
}
