import AuthService from '@/api/services/auth/auth.service';
import { ApiErrorMessages } from '@/enums/api-error-messages.enum';
import { ChangePassword } from '@/models/auth/change-password.class';
import { Login } from '@/models/auth/login.class';
import { Register } from '@/models/auth/register.class';
import { RequestPasswordReset } from '@/models/auth/request-password-reset.class';
import { ResetPassword } from '@/models/auth/reset-password.class';
import { User } from '@/models/users/user.class';
import { apiErrorMessageToTranslationKey } from '@/utils/helpers';
import axios, { AxiosResponse } from 'axios';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const authService = new AuthService();

export interface AuthState {
  authenticatedUser?: User;
  token: string;
  alert: boolean;
}

const initialState: AuthState = {
  authenticatedUser: undefined,
  token: localStorage.getItem('token') || '',
  alert: false,
};

const getters: GetterTree<AuthState, RootState> = {
  authenticatedUser(state) {
    return state.authenticatedUser;
  },
  token(state) {
    return state.token;
  },
  isLoggedIn(state) {
    return !!state.token;
  },
};

const mutations: MutationTree<AuthState> = {
  setUser(state, payloadauthenticatedUser: User) {
    state.authenticatedUser = payloadauthenticatedUser;
  },
  setToken(state, payloadToken: string) {
    state.token = payloadToken;
    if (payloadToken) {
      // saving token in localStorage
      localStorage.setItem('token', state.token);
    } else {
      localStorage.removeItem('token');
      delete axios.defaults.headers.common.Authorization;
    }
  },
};

const actions: ActionTree<AuthState, RootState> = {
  async login(context, userAuth: Login) {
    try {
      const response = await authService.login(userAuth);
      // set token
      context.commit('setToken', response.data.token);
      // set user
      context.commit('setUser', response.data.user);
      return true;
    } catch (error) {
      const response = error.response as AxiosResponse;
      if (response && response.status === 401) {
        const apiErrorMessage = response.data.message as ApiErrorMessages;

        context.commit('setUser', null);
        context.commit('setToken', null);
        return apiErrorMessageToTranslationKey(apiErrorMessage);
      }
    }
  },
  async getMe(context) {
    try {
      const response = await authService.getMyInformation();
      context.commit('setUser', response.data);

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        context.commit('setUser', null);
        context.commit('setToken', null);
        context.dispatch('app/error', 'Login has expired', { root: true });
      }
    }
    return null;
  },
  async logOut(context) {
    // deleting token
    await localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
    context.commit('setToken', null);
    context.commit('setUser', null);
    context.dispatch('app/success', 'You have been logged out', {
      root: true,
    });
    return true;
  },
  // TODO: Add register call to API with catch if error
  async register(context, newUser: Register) {
    try {
      const response = await authService.registerUser(newUser);

      return response;
    } catch (error) {
      const response = error.response as AxiosResponse;
      if (response && response.status) {
        const apiErrorMessage = response.data.message as ApiErrorMessages;
        return apiErrorMessageToTranslationKey(apiErrorMessage);
      }
    }
  },
  async requestPasswordReset(context, requestPassword: RequestPasswordReset) {
    try {
      const response = await authService.requestPasswordReset(requestPassword);

      return response;
    } catch (error) {
      const response = error.response as AxiosResponse;
      if (response && response.status === 401) {
        const apiErrorMessage = response.data.message as ApiErrorMessages;
        return apiErrorMessageToTranslationKey(apiErrorMessage);
      }
    }
  },
  async resetPassword(context, password: ResetPassword) {
    try {
      const response = await authService.resetPassword(password);

      const user = response.data;
      const login: Login = {
        email: user.email,
        password: password.password,
      };

      context.dispatch('login', login);
      return true;
    } catch (error) {
      context.dispatch('app/error', 'Could not reset password', { root: true });
    }
  },

  async changePassword(context, changePassword: ChangePassword) {
    try {
      const response = authService.changePassword(changePassword);
      return response;
    } catch (error) {
      return error.response;
    }
  },
};

const authModule: Module<AuthState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default authModule;
