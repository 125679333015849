import BookingRoomService from '@/api/services/bookingRoom/bookingRoom.service';
import BookingOffer from '@/api/services/bookingOffer/bookingOffer.service';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import MeetingRoomBooking from '@/models/facilities/meeting-room-booking.class';
import { AxiosError } from 'axios';
import { generateReadableAxiosError } from '@/utils/generate-readable-axios-error';

const bookingRoomService = new BookingRoomService();
const bookingOffer = new BookingOffer();

export interface BookingsState {
  bookings: MeetingRoomBooking[];
  bookingOffer: string;
}

export interface UpdateBooking {
  id: string;
  booking: MeetingRoomBooking;
}

const initialState: BookingsState = {
  bookings: [],
  bookingOffer: '',
};

const getters: GetterTree<BookingsState, RootState> = {
  bookings: state => state.bookings,
  bookingOffer: state => state.bookingOffer,
};

const mutations: MutationTree<BookingsState> = {
  setAll(state, payload: MeetingRoomBooking[]) {
    state.bookings = payload;
  },
  setBookingToAll(state, payload: MeetingRoomBooking) {
    state.bookings = state.bookings.map(booking => {
      if (booking._id === payload._id) {
        return payload;
      }

      return booking;
    });
  },
  setBookingOffer(state, payload: string) {
    state.bookingOffer = payload;
  },
};

const actions: ActionTree<BookingsState, RootState> = {
  async fetchBookings(context, filter?: FetchAllParams) {
    try {
      const bookings = await bookingRoomService.fetch(filter);
      context.commit('setAll', bookings);

      return bookings;
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    }
  },
  async fetchBookingsByUserId(context, userId: string) {
    try {
      const bookings = await bookingRoomService.fetchBookingsByUserId(userId);
      context.commit('setAll', bookings);
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    }
  },
  async updateBooking(context, payload: UpdateBooking) {
    try {
      const booking = await bookingRoomService.update(payload.id, payload.booking);
      context.commit('setBookingToAll', booking);

      return booking;
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    }
  },
  async approveBookingOffer(
    context,
    payload: { booking: MeetingRoomBooking; customerReference: string },
  ) {
    const { booking, customerReference } = payload;
    try {
      return await bookingRoomService.approveBookingOffer(booking, customerReference);
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    }
  },
  async fetchBookingOffer(context, payload) {
    try {
      const invoice = await bookingOffer.createBookingOffer(payload);
      context.commit('setBookingOffer', invoice);

      return invoice;
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    }
  },
  clearOne(context) {
    context.commit('clearOne');
  },
};

const bookingsModule: Module<BookingsState, RootState> = {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: initialState,
};
export default bookingsModule;
