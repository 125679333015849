import CoursesService from '@/api/services/courses/courses.service';
import SessionsService from '@/api/services/sessions/sessions.service';
import Course from '@/models/courses/course.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import Session from '../../models/sessions/session.class';
import { RootState } from '../store';
import { FilterOperation } from '@/models/app/filter-operation.enum';

const coursesService = new CoursesService();
const sessionsService = new SessionsService();

export interface CourseState {
  all: Course[];
  one: Course;
  apiParams: FetchAllParams;
  futureSessions: Session[];
  highlightedCourses: Course[];
}

const initialState: CourseState = {
  all: [],
  one: {} as Course,
  apiParams: {},
  futureSessions: [],
  highlightedCourses: [],
};

const getters: GetterTree<CourseState, RootState> = {
  one: state => state.one,
  all: state => state.all,
  apiParams: state => state.apiParams,
  futureSessions: state => state.futureSessions,
  highlightedCourses: state => state.highlightedCourses,
};

const mutations: MutationTree<CourseState> = {
  setAll(state, payload: Course[]) {
    state.all = payload;
  },
  setHighlightedCourses(state, payload: Course[]) {
    state.highlightedCourses = payload;
  },
  setFutureSessions(state, payload: Session[]) {
    state.futureSessions = payload;
  },
  setApiParams(state, payload: object) {
    state.apiParams = { ...state.apiParams, ...payload };
  },
  clearData(state) {
    state.all = [];
    state.apiParams = {};
  },
  setOne(state, payload: Course) {
    state.one = payload;
  },
  clearOne(state) {
    state.one = {} as Course;
    state.futureSessions = [];
  },
};

const actions: ActionTree<CourseState, RootState> = {
  async fetchFilteredCourses(context, payload?: FetchAllParams) {
    try {
      const currentFilter = context.getters.apiParams;
      const params = { ...currentFilter, ...payload };
      context.commit('setApiParams', params);

      const courses = await coursesService.fetchCourses(params);
      context.commit('setAll', courses);
      return courses;
    } catch (error) {
      return error.response;
    }
  },
  async fetchOne(context, payload: string) {
    try {
      const course = await coursesService.fetchCourse(payload);
      if (course) {
        for (const session of course.sessions) {
          if (session === null) {
            continue;
          }
          sessionsService
            .fetchRegistrationCount(session._id as string)
            .then(response => (session.registrationCount = response));
        }
      }

      context.commit('setOne', course);
      return course;
    } catch (error) {
      return error.response;
    }
  },
  async fetchFutureSessions(context, payload: string) {
    try {
      const sessions = await coursesService.fetchFutureSessions(payload);
      context.commit('setFutureSessions', sessions);
      return sessions;
    } catch (error) {
      return error.response;
    }
  },

  setApiParams(context, payload: FetchAllParams) {
    context.commit('setApiParams', payload);
  },
  clearData(context) {
    context.commit('clearData');
  },
  clearOne(context) {
    context.commit('clearOne');
  },
  async fetchHighlightedCourses(context) {
    try {
      const params: FetchAllParams = {
        filters: { showOnHomePage: { value: true, operation: FilterOperation.Equals } },
      };

      const courses = await coursesService.fetchCourses(params);
      context.commit('setHighlightedCourses', courses);
      return courses;
    } catch (error) {
      return error.response;
    }
  },
};

const coursesModule: Module<CourseState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default coursesModule;
