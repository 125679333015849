import CustomersService from '@/api/services/customers/customers.service';
import UsersService from '@/api/services/users/users.service';
import { ApiErrorMessages } from '@/enums/api-error-messages.enum';
import { SortOrder } from '@/enums/sort-order.enum';
import { Customer } from '@/models/customers/customer.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { PaginatedResponse } from '@/models/shared/paginated-response.interface';
import { User } from '@/models/users/user.class';
import { apiErrorMessageToTranslationKey } from '@/utils/helpers';
import { AxiosResponse } from 'axios';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const usersService = new UsersService();
const customersService = new CustomersService();

export interface UsersState {
  allUnpaginated: User[];
  all: { docs: User[] };
  one: User | null;
  apiParams: FetchAllParams;
}

const initialApiParams: FetchAllParams = {
  limit: 999,
  sort: [`sequence,${SortOrder.Asc}`],
};

const initialState: UsersState = {
  one: null,
  allUnpaginated: [],
  all: { docs: [] },
  apiParams: initialApiParams,
};

const getters: GetterTree<UsersState, RootState> = {
  GET: (state: UsersState) => state.one,
  ALL: (state: UsersState) => state.allUnpaginated,
  ALL_PAGINATED: (state: UsersState) => state.all,
  API_PARAMS: (state: UsersState) => state.apiParams,
};

const mutations: MutationTree<UsersState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: PaginatedResponse<User>) {
    state.all = payload;
  },
  CLEAR_ALL(state) {
    state.all = { docs: [] };
    state.apiParams = initialApiParams;
  },
  SET_UNPAGINATED(state, payload: User[]) {
    state.allUnpaginated = payload;
  },
  SET_ONE(state, payload: User) {
    state.one = payload;
  },
};

const actions: ActionTree<UsersState, RootState> = {
  async CREATE(context, payload?: User) {
    context.dispatch('app/setLoading', null, { root: true });
    try {
      if (!payload) {
        return null;
      }
      const res = await usersService.create(payload);
      return res;
    } catch (error) {
      const response = error.response as AxiosResponse;
      if (response && response.status === 403) {
        const apiErrorMessage = response.data.message as ApiErrorMessages;
        return apiErrorMessageToTranslationKey(apiErrorMessage);
      } else {
        return apiErrorMessageToTranslationKey('' as ApiErrorMessages);
      }
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('app/setLoading', null, { root: true });
      const user = await usersService.fetchOne(id);
      context.commit('SET_ONE', user);
      return user;
    } catch (error) {
      return null; // TODO better error handling
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async FETCH_ONE_PUBLIC(context, id: string) {
    try {
      context.dispatch('app/setLoading', null, { root: true });
      const user = await usersService.fetchOne(id);
      context.commit('SET_ONE', user);
      return user;
    } catch (error) {
      return null; // TODO better error handling
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async FETCH_ALL(context, filter?: FetchAllParams) {
    try {
      context.dispatch('app/setLoading', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const users = await usersService.fetch(context.getters.API_PARAMS);
      context.commit('SET_ALL', users);
      return users;
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async FETCH_ALL_PUBLIC(context, filter?: FetchAllParams) {
    try {
      context.commit('SET_API_PARAMS', filter);
      const users = await usersService.fetch(context.getters.API_PARAMS);
      context.commit('SET_ALL', users);
      return users;
    } finally {
      // eslint-disable-next-line no-empty
    }
  },
  async CLEAR_ALL(context) {
    try {
      context.commit('CLEAR_ALL');
      return true;
    } finally {
      // eslint-disable-next-line no-empty
    }
  },
  async FETCH_UNPAGINATED(context, filter?: FetchAllParams) {
    try {
      context.dispatch('app/setLoading', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const users = await usersService.fetchAll(context.getters.API_PARAMS);
      context.commit('SET_UNPAGINATED', users);
      return users;
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async UPDATE(context, payload: { _id: string; user: User }) {
    try {
      // context.dispatch('app/setLoading', null, { root: true });
      const user = await usersService.update(payload._id, payload.user);
      // context.commit('SET_ONE', user);
      return user;
    } finally {
      // context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async updateCustomer(context, payload: Customer) {
    try {
      if (payload._id) {
        const customer = await customersService.update(payload._id, payload);
        context.dispatch('auth/getMe', null, { root: true });

        return customer;
      }
    } finally {
      // context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
};

const usersModule: Module<UsersState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default usersModule;
