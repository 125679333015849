import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCheck,
  faExclamationCircle as fadExclamationCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faShareAlt,
  faEnvelope,
  faDownload,
  faEye,
  faEyeSlash,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
  faCheck,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faEye,
  faEyeSlash,
  faExclamationCircle,
  fadExclamationCircle,
  faExclamationTriangle,
  faSpinner,
  faFacebook,
  faTwitterSquare,
  faLinkedin,
  faShareAlt,
  faEnvelope,
  faDownload,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
