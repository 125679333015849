import FacilitiesRoomTypesService from '@/api/services/facilities/facilitiesRoomTypes.service';
import RoomType from '@/models/facilities/room-type.class';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';

const facilitiesRoomTypesService = new FacilitiesRoomTypesService();

export interface RoomTypesState {
  selectedRoomType: RoomType | null;
  roomTypes: RoomType[];
}

const initialState: RoomTypesState = {
  selectedRoomType: null,
  roomTypes: [],
};

const getters: GetterTree<RoomTypesState, RootState> = {
  selectedRoomType: state => state.selectedRoomType,
  roomTypes: state => state.roomTypes,
};

const mutations: MutationTree<RoomTypesState> = {
  setOne(state, payload: RoomType) {
    state.selectedRoomType = payload;
  },
  clearOne(state) {
    state.selectedRoomType = null;
  },
  setAll(state, payload: RoomType[]) {
    state.roomTypes = payload;
  },
};

const actions: ActionTree<RoomTypesState, RootState> = {
  async fetchOne(context, idOrSlug: string) {
    try {
      context.dispatch('app/setLoading', null, { root: true });
      const roomType = await facilitiesRoomTypesService.fetchOne(idOrSlug);
      context.commit('setOne', roomType);
      return roomType;
    } catch (error) {
      return error.response;
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async fetch(context, filter?: FetchAllParams) {
    try {
      const roomTypes = await facilitiesRoomTypesService.fetch(filter);
      context.commit('setAll', roomTypes);
      return roomTypes;
    } catch (error) {
      return error.response;
    }
  },
  clearOne(context) {
    context.commit('clearOne');
  },
};

const roomTypesModule: Module<RoomTypesState, RootState> = {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: initialState,
};
export default roomTypesModule;
