import { ApiErrorMessages } from '@/enums/api-error-messages.enum';

export function apiErrorMessageToTranslationKey(messageString: ApiErrorMessages): string {
  const errorMessagesKey = 'errorMessages';

  switch (messageString) {
    case ApiErrorMessages.EmailNotFound:
    case ApiErrorMessages.UserNotFound:
      return `${errorMessagesKey}.userNotFound`;

    case ApiErrorMessages.WrongPassword:
      return `${errorMessagesKey}.wrongPassword`;

    case ApiErrorMessages.UserExists:
    case ApiErrorMessages.EmailAlreadyInUse:
      return `${errorMessagesKey}.userExists`;

    case ApiErrorMessages.SubscriberAlreadyExists:
      return `${errorMessagesKey}.subscriberExists`;

    case ApiErrorMessages.StudentHasRegistrations:
      return `${errorMessagesKey}.studentHasRegistrations`;

    default:
      return `${errorMessagesKey}.defaultError`;
  }
}

export function humanReadableFileSize(size: number) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const total = size / Math.pow(1024, i);
  return `${total.toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export function formatExternalLink(link: string) {
  const httpString = 'http://';
  const httpsString = 'https://';

  const startsWithHttps = link.includes(httpString) || link.includes(httpsString);

  return startsWithHttps ? link : `${httpsString}${link}`;
}
