import CustomersService from '@/api/services/customers/customers.service';
import { Customer } from '@/models/customers/customer.class';

import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const customersService = new CustomersService();

export interface CustomersState {
  one: Customer | null;
}

const initialState: CustomersState = {
  one: null,
};

const getters: GetterTree<CustomersState, RootState> = {
  GET: (state: CustomersState) => state.one,
};

const mutations: MutationTree<CustomersState> = {
  SET_ONE(state, payload: Customer) {
    state.one = payload;
  },
};

const actions: ActionTree<CustomersState, RootState> = {
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('app/setLoading', null, { root: true });
      const user = await customersService.fetchOne(id);
      context.commit('SET_ONE', user);
      return user;
    } catch (error) {
      return null; // TODO better error handling
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
};

const customersModule: Module<CustomersState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default customersModule;
