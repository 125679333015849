import api from '@/api/api';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { Student } from '@/models/students/student.class';
import { generateRequestConfig } from '@/utils/generate-request-config.util';
import { AxiosRequestConfig } from 'axios';
import EntitiesService from '../entities/entities.service';

export default class StudentsService extends EntitiesService<Student> {
  get endpoint() {
    return '/students';
  }

  async fetchStudents(params?: FetchAllParams, endpoint?: string): Promise<Student[]> {
    const config: AxiosRequestConfig = generateRequestConfig(params);
    const response = await api.get<Student[]>(endpoint || this.endpoint, config);
    return response.data;
  }
}
