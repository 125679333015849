
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({
  name: 'Searchbar',
  components: {},
})
export default class TheSearchbar extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  searchInput!: string;

  @Prop({
    type: Boolean,
    default: true,
  })
  useBackground!: boolean;

  searchQuery = '';

  @Watch('searchInput', { immediate: true })
  onSearchInputChanged() {
    this.searchQuery = this.searchInput;
  }

  @Watch('searchQuery')
  async onSearchQueryChanged() {
    this.onSearch();
  }

  @Emit('search')
  onSearch() {
    return this.searchQuery;
  }
}
