import NewsletterSubscription from '@/models/newsletter-subscription/newsletter-subscription.class';
import EntitiesService from '../entities/entities.service';

export default class NewsletterSubscriptionsService extends EntitiesService<
  NewsletterSubscription
> {
  get endpoint() {
    return '/newsletter-subscriptions';
  }
}
