import api from '@/api/api';
import StaffMember from '@/models/staff-members/staff-member.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { generateRequestConfig } from '@/utils/generate-request-config.util';
import { AxiosRequestConfig } from 'axios';
import EntitiesService from '../entities/entities.service';

export default class StaffMembersService extends EntitiesService<StaffMember> {
  get endpoint() {
    return '/staff-members';
  }

  async fetchStaffMembers(params?: FetchAllParams): Promise<StaffMember[]> {
    try {
      const config: AxiosRequestConfig = generateRequestConfig(params);
      const response = await api.get<StaffMember[]>(`${this.endpoint}/all`, config);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
}
