export enum StaticContentType {
  Contact = 'contact',
  Classrooms = 'classrooms',
  Tailored = 'tailored',
  About = 'about',
  Banner = 'banner',
  Footer = 'footer',
  Privacy = 'privacy',
  Disclaimer = 'disclaimer',
  Cookie = 'cookie',
  Course = 'course',
  Homepage = 'homepage',
  HomepageOffer = 'homepage-offer',
  Coaching = 'coaching',
  HomepageUsp = 'homepage-usps',
}
