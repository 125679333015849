import NewsletterSubscriptionsService from '@/api/services/newsletters/newsletter-subscriptions.service';
import { ApiErrorMessages } from '@/enums/api-error-messages.enum';
import NewsletterSubscription from '@/models/newsletter-subscription/newsletter-subscription.class';
import { apiErrorMessageToTranslationKey } from '@/utils/helpers';
import { AxiosResponse } from 'axios';
import { ActionTree, Module } from 'vuex';
import { RootState } from '../store';

const newsletterSubscriptionsService = new NewsletterSubscriptionsService();

export interface NewsletterSubscriptionState {
  newsletterSubscription: NewsletterSubscription | null;
}
const initialState: NewsletterSubscriptionState = {
  newsletterSubscription: null,
};

const actions: ActionTree<NewsletterSubscriptionState, RootState> = {
  async CREATE(context, newsletterSubscription: NewsletterSubscription) {
    try {
      const response = await newsletterSubscriptionsService.create(newsletterSubscription);
      return response;
    } catch (error) {
      const response = error.response as AxiosResponse;
      if (response && response.status === 401) {
        const apiErrorMessage = response.data.message as ApiErrorMessages;
        return apiErrorMessageToTranslationKey(apiErrorMessage);
      } else {
        return apiErrorMessageToTranslationKey('' as ApiErrorMessages);
      }
    }
  },
};

const newsletterSubscriptionsModule: Module<NewsletterSubscriptionState, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
};

export default newsletterSubscriptionsModule;
