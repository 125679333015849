import { BaseModel } from '../base-model.class';

export class Student extends BaseModel {
  firstName = '';
  lastName = '';
  dateOfBirth?: string;
  birthPlace?: string;
  email = '';
  customer = '';
  phone = '';

  constructor(firstName = '', lastName = '', email = '', customer = '') {
    super();
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.customer = customer;
  }
}
