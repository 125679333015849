import api from '@/api/api';
import Course from '@/models/courses/course.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { generateRequestConfig } from '@/utils/generate-request-config.util';
import { AxiosRequestConfig } from 'axios';
import Session from '../../../models/sessions/session.class';
import EntitiesService from '../entities/entities.service';

export default class CoursesService extends EntitiesService<Course> {
  get endpoint() {
    return '/courses';
  }

  async fetchCourses(params?: FetchAllParams): Promise<Course[]> {
    try {
      const config: AxiosRequestConfig = generateRequestConfig(params);
      const response = await api.get<Course[]>(`${this.endpoint}/filteredCourses`, config);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }

  async fetchCourse(idOrSlug: string) {
    try {
      const response = await api.get<Course>(`${this.endpoint}/${idOrSlug}`);
      return response.data;
    } catch (error) {
      error.message;
    }
  }

  async fetchFutureSessions(id: string) {
    try {
      const response = await api.get<Session>(`${this.endpoint}/${id}/sessions`);
      return response.data;
    } catch (error) {
      error.message;
    }
  }
}
