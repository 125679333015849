import { Locale } from '@/models/shared/locale.interface';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const appModule = namespace('app');

@Component
export class UseCurrentLocaleMixin extends Vue {
  @appModule.Getter('currentLocale')
  currentLocale!: Locale;

  get localeCode() {
    return this.currentLocale.code;
  }

  get localeBaseUrlPath() {
    return this.currentLocale.base;
  }
}
