import StaticContentsService from '@/api/services/static-contents/static-contents.service';
import { StaticContentType } from '@/enums/static-content-type.enum';
import { StaticContent } from '@/models/static-contents/static-content.interface';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const staticContentsService = new StaticContentsService();

// TODO (ERISON): refactor and implement better typing for StaticContent

export interface StaticContentsState {
  contents: Partial<{ [type in StaticContentType]: unknown }>;
}

const initialState: StaticContentsState = {
  contents: {},
};

const getters: GetterTree<StaticContentsState, RootState> = {
  getStaticContentByType: state => (type: StaticContentType) => {
    return state.contents[type];
  },
};

const mutations: MutationTree<StaticContentsState> = {
  setStaticContent(state, staticContent: StaticContent) {
    state.contents[staticContent.type] = staticContent.data;
  },
};

const actions: ActionTree<StaticContentsState, RootState> = {
  async fetchStaticContent(context, type: StaticContentType) {
    const response = await staticContentsService.fetchOne(type);
    context.commit('setStaticContent', response);

    return response.data;
  },
};

const staticContentsModule: Module<StaticContentsState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default staticContentsModule;
