import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import AppLanguageWrapper from './AppLanguageWrapper.vue';
import { RouterView } from './enums/router-view.enum';
import { StaticContentType } from './enums/static-content-type.enum';
import { Locale } from './models/shared/locale.interface';
import { Banner } from './models/static-contents/banner.interface';
import { User } from './models/users/user.class';
import i18n from './plugins/i18n';
import store from './store/store';
import { SUPPORTED_LOCALES } from './utils/constants';
import { translateApi } from './utils/translate-api';

Vue.use(Router);

function loadView(view: string) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

const routes: RouteConfig[] = [
  {
    path: '',
    name: 'home',
    component: loadView(RouterView.Home),
  },
  {
    path: 'courses',
    name: 'courses',
    component: loadView(RouterView.Courses),
  },
  {
    path: 'courses/:slug',
    name: 'course',
    component: loadView(RouterView.CourseDetail),
  },
  {
    path: 'courses/:id/register/:sessionId',
    name: 'courseRegister',
    component: loadView(RouterView.CourseRegister),
  },
  {
    path: 'login',
    name: 'login',
    component: loadView(RouterView.LoginRegister),
  },
  {
    path: 'password-reset',
    name: 'PasswordResetRequest',
    component: loadView(RouterView.PasswordResetRequest),
  },
  {
    path: 'password-reset/:token',
    name: 'passwordReset',
    component: loadView(RouterView.PasswordReset),
  },

  {
    path: 'about',
    name: 'about',
    component: loadView(RouterView.AboutUs),
  },
  {
    path: 'my-account',

    component: loadView(RouterView.MyAccount),
    children: [
      {
        name: 'registration-data',
        path: 'registration-data',
        component: loadView(RouterView.RegistrationData),
      },
      {
        name: 'bookings-data',
        path: 'bookings-data',
        component: loadView(RouterView.BookingsData),
      },
      { name: 'my-account', path: '', component: loadView(RouterView.PersonalData) },
      { name: 'login-data', path: 'login-data', component: loadView(RouterView.LoginData) },
      { name: 'invoice-data', path: 'invoice-data', component: loadView(RouterView.InvoiceData) },
      {
        name: 'participants',
        path: 'participants',
        component: loadView(RouterView.Participants),
      },
    ],
  },
  {
    path: 'coaching',
    name: 'coaching',
    component: loadView(RouterView.Coaching),
  },
  {
    path: 'newsletter',
    name: 'newsletter',
    component: loadView(RouterView.NewsletterRegistration),
  },
  {
    path: 'facilities',
    name: 'facilities',
    component: loadView(RouterView.Facilities),
  },
  {
    path: 'facilitiesBooking',
    name: 'facilitiesBooking',
    component: loadView(RouterView.FacilitiesBooking),
  },
  {
    path: 'disclaimer',
    name: 'disclaimer',
    component: loadView(RouterView.Disclaimer),
  },
  {
    path: 'privacy',
    name: 'privacy',
    component: loadView(RouterView.Privacy),
  },
  {
    path: 'contact',
    name: 'contact',
    component: loadView(RouterView.Contact),
  },
  {
    path: 'billing/redirect/:id',
    name: 'billingRedirect',
    component: loadView(RouterView.BillingRedirect),
  },
  {
    path: 'billing/complete/:id',
    name: 'billingComplete',
    component: loadView(RouterView.BillingComplete),
  },
  {
    path: 'page/:slug',
    name: 'staticPage',
    component: loadView(RouterView.StaticPage),
  },
  {
    path: '*',
    name: 'pageNotFound',
    component: loadView(RouterView.PageNotFound),
  },
];

// Creates regex (en|nl|fr)
function getLocaleRegex() {
  let reg = '';
  SUPPORTED_LOCALES.forEach((locale, index) => {
    reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`;
  });
  return `(${reg})`;
}

// Returns locale configuration
function getLocale(locale = 'nl'): Locale {
  return SUPPORTED_LOCALES.find(loc => loc.code === locale) as Locale;
}

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { y: 200, x: 0 }, // skip the height of the navbars, so that the correct anchor is shown
      };
    }

    // Return to top on route change
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: `/:locale${getLocaleRegex()}?`,
      component: AppLanguageWrapper,
      children: routes,
    },
  ],
});

// global guard
router.beforeEach(async (to, from, next) => {
  const loggedOutOnlyRoutes = ['login', 'passwordResetRequest', 'passwordReset'];

  const hasToken = !!window.localStorage.getItem('token');
  const isLoggedIn: boolean = store.getters['auth/isLoggedIn'];
  let user: User = store.getters['auth/authenticatedUser'];
  const headerHtml = store.getters['app/headerHtml'];

  // Set app language according to URL
  const selectedLocale = getLocale(to.params.locale);
  const currentLocale = i18n.locale;

  if (selectedLocale.code !== currentLocale) {
    store.dispatch('app/setLocale', selectedLocale);
    i18n.locale = selectedLocale.code;
  }
  const baseUrl = selectedLocale.base;

  //check if the headerHtml has a state
  if (!headerHtml) {
    const banner: Banner = await store.dispatch(
      'staticContents/fetchStaticContent',
      StaticContentType.Banner,
    );
    store.dispatch('app/setHeaderHtml', translateApi(banner.title.value, selectedLocale.code));
  }

  // redirects to home when loggedIn route is loggedOutOnly
  if (to.name && loggedOutOnlyRoutes.includes(to.name) && hasToken) {
    return next(`${baseUrl}/`);
  }

  // gets User when loggedIn and userInfo is empty
  if (isLoggedIn && !user) {
    const userResult = await store.dispatch('auth/getMe');
    user = store.getters['auth/authenticatedUser'];

    if (!userResult) {
      next(`${baseUrl}/login`);
    }
  }

  // checks if user has requiredRole, otherwise redirects to HomePage
  if (isLoggedIn && to.meta?.requiresRoles) {
    const hasRequiredRole = to.meta.requiresRoles.find((role: string) => {
      return role === user.role;
    });

    if (hasRequiredRole) {
      next();
    } else {
      // TODO: Add toast with page forbidden
      next(`${baseUrl}/`);
    }
  }

  // redirects to login when route requiresAuth and !loggedIn
  if (to.matched.some(record => record.meta.requiresAuth) && !hasToken) {
    return next(`${baseUrl}/login`);
  }

  return next();
});

export default router;
