/* eslint-disable no-useless-catch */
import api from '@/api/api';
import { ChangePassword } from '@/models/auth/change-password.class';
import { LoginResponse } from '@/models/auth/login-response.interface';
import { Login } from '@/models/auth/login.class';
import { Register } from '@/models/auth/register.class';
import { RequestPasswordReset } from '@/models/auth/request-password-reset.class';
import { ResetPassword } from '@/models/auth/reset-password.class';
import { User } from '@/models/users/user.class';

const endpoint = '/auth';

export default class AuthService {
  async login(userAuth: Login) {
    try {
      const response = await api.post<LoginResponse>(`${endpoint}/login`, userAuth);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getMyInformation() {
    try {
      const response = await api.get<User>(`users/me`);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async registerUser(newUser: Register) {
    try {
      const response = await api.post<User>(`${endpoint}/register`, newUser);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async requestPasswordReset(requestPassword: RequestPasswordReset) {
    try {
      const response = await api.post<boolean>(`${endpoint}/reset-password`, requestPassword);

      return response;
    } catch (error) {
      throw error;
    }
  }

  async resetPassword(resetPassword: ResetPassword) {
    const { password, confirmPassword, token } = resetPassword;

    try {
      const response = await api.post<User>(`${endpoint}/reset-password/${token}`, {
        password,
        confirmPassword,
      });

      return response;
    } catch (error) {
      throw error;
    }
  }

  async changePassword(changePassword: ChangePassword) {
    try {
      const response = await api.post<User>(`${endpoint}/change-password`, changePassword);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
