import api from '@/api/api';
import Session from '@/models/sessions/session.class';
import EntitiesService from '../entities/entities.service';

export default class SessionsService extends EntitiesService<Session> {
  get endpoint() {
    return '/sessions';
  }

  async fetchRegistrationCount(sessionId: string) {
    const response = await api.get<number>(`${this.endpoint}/${sessionId}/registration-count`);
    return response.data;
  }
}
