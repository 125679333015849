
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { translateApi } from '../utils/translate-api';
import { StaticContentType } from '../enums/static-content-type.enum';
import { Footer } from '../models/static-contents/footer.interface';
import { StaticContent } from '../models/static-contents/static-content.interface';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';

const staticContentsModule = namespace('staticContents');

@Component({
  components: {},
  filters: {
    translateApi,
  },
})
export default class FooterContactInfo extends Mixins(UseCurrentLocaleMixin) {
  @staticContentsModule.Action('fetchStaticContent')
  fetchStaticContent!: (type: StaticContentType) => Promise<StaticContent>;

  @staticContentsModule.Getter('getStaticContentByType')
  getStaticContentByType!: (type: StaticContentType) => Footer;

  footer: Footer | null = null;

  get addressContent() {
    if (this.footer) {
      return translateApi(this.footer.syntraAddressLabel.value, this.$i18n.locale);
    }
    return '';
  }

  get phoneContent() {
    if (this.footer) {
      return translateApi(this.footer.companyPhone.value, this.$i18n.locale);
    }
    return '';
  }

  get emailContent() {
    if (this.footer) {
      return translateApi(this.footer.companyEmail.value, this.$i18n.locale);
    }
    return '';
  }

  get isContactPage() {
    return this.$route.name === 'contact';
  }

  async created() {
    if (!this.getStaticContentByType(StaticContentType.Footer)) {
      await this.fetchStaticContent(StaticContentType.Footer);
    }

    this.footer = this.getStaticContentByType(StaticContentType.Footer);
  }
}
