
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { translateApi } from '../utils/translate-api';
import { HomepageOffer as HomepageOfferModel } from '@/models/static-contents/homepage-offer.interface';
import HomepageOfferItem from '@/components/HomepageOfferItem.vue';
import { UseViewWidthHelpersMixin } from '@/mixins/use-view-width-helpers.mixin';
import { CourseType } from '@/enums/course-type.enum';
import { UseCurrentLocaleMixin } from '@/mixins/use-current-locale.mixin';
import { namespace } from 'vuex-class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { FilterOperation } from '@/models/app/filter-operation.enum';
import { createRouteWithLocaleParam } from '@/utils/create-route-with-locale-param';
import { RawLocation } from 'vue-router';

const coursesModule = namespace('courses');

@Component({
  components: {
    HomepageOfferItem,
  },
  filters: {
    translateApi,
  },
})
export default class HomepageOffer extends Mixins(UseViewWidthHelpersMixin, UseCurrentLocaleMixin) {
  @Prop({
    type: Object,
    required: true,
  })
  homepageOffer!: HomepageOfferModel;

  @coursesModule.Action('setApiParams')
  setApiParams!: (payload: FetchAllParams) => void;

  courseType = CourseType;

  get courseTypeValues() {
    return Object.values(CourseType) as string[];
  }

  onSeeMoreClicked(param: string) {
    let route: RawLocation;

    if (this.courseTypeValues.includes(param)) {
      this.setApiParams({
        filters: {
          types: {
            value: [param],
            operation: FilterOperation.Equals,
          },
        },
      });

      route = createRouteWithLocaleParam('courses', this.localeCode);
    } else {
      route = createRouteWithLocaleParam(param, this.localeCode);
    }

    this.$router.push(route);
  }
}
