export class BaseModel {
  _id?: string;

  toApi(): unknown {
    return { ...this };
  }

  fromApi(dto: object) {
    const props = Object.getOwnPropertyNames(this);
    for (const prop of props) {
      this[prop] = dto[prop];
    }
  }
}
