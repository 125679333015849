import router from '@/router';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import env from '../../env.config';

const GOOGLE_ANALYTICS_ID = env.GOOGLE_ANALYTICS_ID;

Vue.use(
  VueGtag,
  {
    config: { id: GOOGLE_ANALYTICS_ID },
    enabled: false,
  },
  router,
);
