import PagesService from '@/api/services/pages/pages.service';
import { Page } from '@/models/pages/page.class';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const pagesService = new PagesService();

export interface PagesState {
  one: Page | null;
}

const initialState: PagesState = {
  one: null,
};

const getters: GetterTree<PagesState, RootState> = {
  one: state => state.one,
};

const mutations: MutationTree<PagesState> = {
  setOne(state, payload: Page) {
    state.one = payload;
  },
  clearOne(state) {
    state.one = null;
  },
};

const actions: ActionTree<PagesState, RootState> = {
  async fetchOne(context, idOrSlug: string) {
    try {
      context.dispatch('app/setLoading', null, { root: true });
      const page = await pagesService.fetchOne(idOrSlug);
      context.commit('setOne', page);
      return page;
    } catch (error) {
      return error.response;
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  clearOne(context) {
    context.commit('clearOne');
  },
};

const pagesModule: Module<PagesState, RootState> = {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: initialState,
};
export default pagesModule;
