import ContactsService from '@/api/services/contacts/contacts.service';
import BookingRoomService from '@/api/services/bookingRoom/bookingRoom.service';
import { SnackbarTypesEnum } from '@/enums/snackbar-types.enum';
import { Snackbar } from '@/models/app/snackbar.class';
import { ContactForm } from '@/models/contact-form/contact-form.class';
import MeetingRoomBooking from '@/models/facilities/meeting-room-booking.class';
import { Locale } from '@/models/shared/locale.interface';
import { SUPPORTED_LOCALES } from '@/utils/constants';
import { localize } from 'vee-validate';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const contactsService = new ContactsService();
const bookingRoomService = new BookingRoomService();

export interface AppState {
  loading: boolean;
  snackbars: Snackbar[];
  windowWidth: number;
  headerHtml: string;
  locale: Locale;
}

const initialState: AppState = {
  loading: false,
  snackbars: [],
  windowWidth: window.innerWidth,
  headerHtml: '',
  locale: SUPPORTED_LOCALES[0], // Dutch is the default language,
};

const getters: GetterTree<AppState, RootState> = {
  snackbars(state) {
    return state.snackbars;
  },
  getLoading: state => state.loading,
  windowWidth: state => state.windowWidth,
  headerHtml: state => state.headerHtml,
  currentLocale: state => state.locale,
};

const mutations: MutationTree<AppState> = {
  loading(state, isLoading: boolean) {
    state.loading = isLoading;
  },
  setSnackbar(state, snackbar: Snackbar) {
    state.snackbars.push(snackbar);
  },
  removeSnackbar(state, index: number) {
    state.snackbars.splice(index, 1);
  },
  setLoading(state, payload: boolean) {
    state.loading = payload;
  },
  setWindowWidth(state, payload: number) {
    state.windowWidth = payload;
  },
  setHeaderHtml(state, payload: string) {
    state.headerHtml = payload;
  },
  clearHeaderHtml(state) {
    state.headerHtml = '';
  },
  setLocale(state, locale: Locale) {
    state.locale = locale;
  },
};

const actions: ActionTree<AppState, RootState> = {
  error(context, message: string) {
    const snackbar = new Snackbar();
    snackbar.message = message;
    snackbar.type = SnackbarTypesEnum.ERROR;
    context.commit('setSnackbar', snackbar);
  },
  success(context, message: string) {
    const snackbar = new Snackbar();
    snackbar.message = message;
    snackbar.type = SnackbarTypesEnum.SUCCESS;
    context.commit('setSnackbar', snackbar);
  },
  setLoading(context) {
    context.commit('setLoading', true);
  },
  unsetLoading(context) {
    context.commit('setLoading', false);
  },
  setWindowWidth(context, payload: number) {
    context.commit('setWindowWidth', payload);
  },

  setHeaderHtml(context, payload: string) {
    context.commit('setHeaderHtml', payload);
  },
  clearHeaderHtml(context) {
    context.commit('clearHeaderHtml');
  },
  setLocale(context, payload: Locale) {
    // Changes vee-validate language
    localize(payload.code);

    context.commit('setLocale', payload);
  },
  async submitContactForm(context, payload: ContactForm) {
    try {
      const response = await contactsService.create(payload);
      if (response) {
        return response;
      }
    } catch (error) {
      return error.response;
    }
  },
  async submitMeetingRoomBookingForm(context, payload: MeetingRoomBooking) {
    try {
      const response = await bookingRoomService.createBooking(payload);
      if (response) {
        return response;
      }
    } catch (error) {
      return error.response;
    }
  },
};

const appModule: Module<AppState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default appModule;
