
import { Component, Mixins } from 'vue-property-decorator';
import { translateApi } from '../utils/translate-api';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';
import FooterContactInfo from './FooterContactInfo.vue';

@Component({
  components: {
    FooterContactInfo,
  },
  filters: {
    translateApi,
  },
})
export default class TheFooter extends Mixins(UseCurrentLocaleMixin) {
  get isContactPage() {
    return this.$route.name === 'contact';
  }
}
