import CustomerReviewsService from '@/api/services/customer-reviews/customer-reviews.service';
import CustomerReview from '@/models/customer-reviews/customer-review.class';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const customerReviewsService = new CustomerReviewsService();

export interface CustomerReviewState {
  all: CustomerReview[];
}

const initialState: CustomerReviewState = {
  all: [],
};

const getters: GetterTree<CustomerReviewState, RootState> = {
  all: state => state.all,
};

const mutations: MutationTree<CustomerReviewState> = {
  setAll(state, payload: CustomerReview[]) {
    state.all = payload;
  },
};

const actions: ActionTree<CustomerReviewState, RootState> = {
  async fetchUnpaginated(context, payload) {
    try {
      const params = { ...payload };
      const customerReviews = await customerReviewsService.fetchCustomerReviews(params);
      context.commit('setAll', customerReviews);
      return customerReviews;
    } catch (error) {
      return error.response;
    }
  },
};

const customerReviewsModule: Module<CustomerReviewState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default customerReviewsModule;
