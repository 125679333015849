import { Locale } from '@/models/shared/locale.interface';

export const SUPPORTED_LOCALES: Locale[] = [
  {
    code: 'nl',
    base: '',
  },
  {
    code: 'en',
    base: '/en',
  },
  {
    code: 'fr',
    base: '/fr',
  },
];
