import InvoicesService from '@/api/services/invoices/invoices.services';
import { SortOrder } from '@/enums/sort-order.enum';
import { Invoice } from '@/models/invoices/invoice.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { PaginatedResponse } from '@/models/shared/paginated-response.interface';
import { generateReadableAxiosError } from '@/utils/generate-readable-axios-error';
import { AxiosError } from 'axios';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '../store';

const invoicesService = new InvoicesService();

export interface InvoicesState {
  allUnpaginated: Invoice[];
  all: { docs: Invoice[] };
  one: Invoice | null;
  apiParams: FetchAllParams;
}

const initialApiParams: FetchAllParams = {
  limit: 999,
  sort: [`sequence,${SortOrder.Asc}`],
};

const initialState: InvoicesState = {
  one: null,
  allUnpaginated: [],
  all: { docs: [] },
  apiParams: initialApiParams,
};

const getters: GetterTree<InvoicesState, RootState> = {
  GET: (state: InvoicesState) => state.one,
  ALL: (state: InvoicesState) => state.allUnpaginated,
  ALL_PAGINATED: (state: InvoicesState) => state.all,
  API_PARAMS: (state: InvoicesState) => state.apiParams,
};

const mutations: MutationTree<InvoicesState> = {
  SET_API_PARAMS(state, payload: FetchAllParams) {
    state.apiParams = payload;
  },
  SET_ALL(state, payload: PaginatedResponse<Invoice>) {
    state.all = payload;
  },
  CLEAR_ALL(state) {
    state.all = { docs: [] };
    state.apiParams = initialApiParams;
  },
  SET_UNPAGINATED(state, payload: Invoice[]) {
    state.allUnpaginated = payload;
  },
  SET_ONE(state, payload: Invoice) {
    state.one = payload;
  },
};

const actions: ActionTree<InvoicesState, RootState> = {
  async CREATE(context, payload?: Invoice) {
    context.dispatch('app/setLoading', null, { root: true });
    try {
      if (!payload) return null;

      const res = await invoicesService.create(payload);

      return res;
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async FETCH_ONE(context, id: string) {
    try {
      context.dispatch('app/setLoading', null, { root: true });
      const invoice = await invoicesService.fetchOne(id);
      context.commit('SET_ONE', invoice);

      return invoice;
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async FETCH_ONE_PUBLIC(context, id: string) {
    try {
      context.dispatch('app/setLoading', null, { root: true });
      const invoice = await invoicesService.fetchOne(id);
      context.commit('SET_ONE', invoice);

      return invoice;
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async FETCH_ALL(context, filter?: FetchAllParams) {
    try {
      context.dispatch('app/setLoading', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const invoices = await invoicesService.fetch(context.getters.API_PARAMS);
      context.commit('SET_ALL', invoices);

      return invoices;
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async FETCH_ALL_PUBLIC(context, filter?: FetchAllParams) {
    try {
      context.commit('SET_API_PARAMS', filter);
      const invoices = await invoicesService.fetch(context.getters.API_PARAMS);
      context.commit('SET_ALL', invoices);

      return invoices;
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    }
  },
  async CLEAR_ALL(context) {
    context.commit('CLEAR_ALL');
  },
  async FETCH_UNPAGINATED(context, filter?: FetchAllParams) {
    try {
      context.dispatch('app/setLoading', null, { root: true });
      context.commit('SET_API_PARAMS', filter);
      const invoices = await invoicesService.fetchAll(context.getters.API_PARAMS);
      context.commit('SET_UNPAGINATED', invoices);

      return invoices;
    } finally {
      context.dispatch('app/unsetLoading', null, { root: true });
    }
  },
  async UPDATE(context, payload: { _id: string; invoice: Invoice }) {
    try {
      const invoice = await invoicesService.update(payload._id, payload.invoice);

      return invoice;
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    }
  },
  async updateInvoice(context, payload: Invoice) {
    try {
      if (payload._id) {
        const invoice = await invoicesService.update(payload._id, payload);
        context.dispatch('auth/getMe', null, { root: true });

        return invoice;
      }
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    }
  },
  async fetchInvoicePdf(context, payload) {
    try {
      const pdf = await invoicesService.getPdf(payload);

      return pdf;
    } catch (error) {
      generateReadableAxiosError(error as AxiosError);
    }
  },
};

const invoicesModule: Module<InvoicesState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};

export default invoicesModule;
