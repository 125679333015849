import { TranslatedProperty } from '@/models/shared/translated-property.class';

export function translateApi(property: TranslatedProperty | undefined, locale: string) {
  if (!property) return '';
  const keys = Object.keys(property);
  if (!keys.length) {
    return '';
  }
  const ret = property[keys[0]];

  for (const key of keys) {
    if (key === locale) {
      return property[key];
    }
  }
  return ret;
}
